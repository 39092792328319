import MeetingDetail from "../containers/meetingDetail";
import { compose, graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
	query meeting($id: ID) {
		meeting: Meeting(id: $id) {
			id
			createdAt
			updatedAt
			name
			description
			public
			password
			active
			moderator {
				id
				firstname
				lastname
			}
			participants {
				id
				firstname
				lastname
			}
		}
	}
`;

const updateMeeting = gql`
	mutation updateMeeting($id: ID!, $active: Boolean, $public: Boolean) {
		updateMeeting(id: $id, active: $active, public: $public) {
			id
			createdAt
			updatedAt
			name
			description
			public
			password
			active
			moderator {
				id
				firstname
				lastname
			}
			participants {
				id
				firstname
				lastname
			}
		}
	}
`;

const QueryParams = {
	options: props => ({
		variables: { id: props.match.params.id },
	}),
};
export default compose(
	graphql(Query, QueryParams),
	graphql(updateMeeting, { name: "updateMeeting" })
)(MeetingDetail);
