import storage from "./storage";

const getUser = () => {
  return storage.get("user") ? storage.get("user", true) : {};
};

const setUser = user => {
  storage.set("user", user, true);
};

const extendUser = user => {
  const current = getUser();
  storage.set("user", { ...current, ...user }, true);
};

export default { getUser, setUser, extendUser };
