import Lesson from "../containers/lesson";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
	query lessons {
		lessons: allLessons {
			id
			createdAt
			updatedAt
			name
			description
		}
	}
`;
export default graphql(Query)(Lesson);
