import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { split } from "apollo-link";
import fetch from "unfetch";
import { GRAPHQL, WSS } from "../constants/server";
import user from "../helpers/user";

const httpLink = createHttpLink({
  uri: GRAPHQL,
  fetch: fetch
});

const wsLink = new WebSocketLink({
  uri: WSS,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const { token } = user.getUser();
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : ""
        }
      };
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const { token } = user.getUser();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
});

export default client;
