import Meeting from "../containers/meeting";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
	query meetings {
		meetings: allMeetings {
			id
			createdAt
			updatedAt
			name
			description
			public
			active
		}
	}
`;

export default graphql(Query)(Meeting);
