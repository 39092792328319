import React, { useState } from "react";
import styled from "styled-components";
import Loading from "../loading";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xl};
  box-sizing: border-box;
  width: 100%;
  position: relative;
  transition: all 0.3s ease-in-out;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: ${({ open }) => (open ? `0` : `100%`)};
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: ${({ theme, first }) =>
    first ? null : `${theme.spacing.base} 0 0 0`};
  box-sizing: border-box;
`;

const Submit = styled.input.attrs({ type: "submit" })`
  flex: 1;
  outline: none;
  margin: 0;
  height: 3em;
  font-size: ${({ theme }) => theme.fontSize.base};
  padding: ${({ theme }) => theme.spacing.sl};
  justify-content: center;
  box-sizing: border-box;
  background: ${({ theme }) => theme.color.blue};
  color: ${({ theme }) => theme.color.white};
  border: none;
  width: 100%;
  max-width: 100%;
  display: flex;
`;

const Input = styled.input`
  flex: 1;
  outline: none;
  margin: 0;
  height: 3em;
  font-size: ${({ theme }) => theme.fontSize.base};
  padding: ${({ theme }) => theme.spacing.xl};
  box-sizing: border-box;
  background: ${({ theme }) => theme.color.grayLight};
  color: ${({ theme }) => theme.color.dark};
  border: none;
  width: 100%;
  max-width: 100%;
  display: flex;
  transition: all 0.3s ease-in-out;
  &:invalid {
    background: ${({ error, theme }) => (error ? theme.color.redLight : null)};
    color: ${({ theme, error }) => (error ? theme.color.white : null)};
  }
`;

//const FieldItem = ({}) => {};

const Error = styled.div`
  flex: 1;
  outline: none;
  margin: 0;
  height: 3em;
  font-size: ${({ theme }) => theme.fontSize.base};
  padding: ${({ theme, open }) =>
    open ? theme.spacing.xl : `0 ${theme.spacing.xl}`};
  margin: ${({ theme, open }) => (open ? `0 0 ${theme.spacing.base} 0` : null)};
  box-sizing: border-box;
  background: ${({ theme }) => theme.color.redLight};
  color: ${({ theme }) => theme.color.dark};
  border: none;
  width: 100%;
  max-width: 100%;
  display: flex;
  transition: all 0.3s ease-in-out;
  max-height: ${({ open }) => (open ? `10em` : `0`)};
  overflow: ${({ open }) => (open ? null : `hidden`)};
`;

export default ({
  fields,
  getChange = () => {},
  error,
  loading,
  submit: { title, action }
}) => {
  const [formData, setFormData] = useState({});
  const [showError, setShowError] = useState(false);
  const onChange = (name, value) => {
    getChange();
    if (value === "" || !value) {
      clearInput(name);
    } else {
      let obj = {};
      obj[name] = value;
      setFormData({ ...formData, ...obj });
    }
  };
  const clearInput = name => {
    const obj = Object.keys(formData).reduce((object, key) => {
      if (key !== name) {
        object[key] = formData[key];
      }
      return object;
    }, {});
    setFormData(obj);
  };
  const getValue = name => formData[name] || "";
  return (
    <Form
      noValidate
      onSubmit={e => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
          setShowError(true);
        } else {
          setShowError(false);
          action(formData);
        }
      }}
    >
      <Error open={error}>{error}</Error>
      {fields.map((field, index) => (
        <Row first={index === 0} key={index}>
          <Input
            error={showError}
            name={field.name}
            type={field.type}
            required={field.required}
            placeholder={field.placeholder}
            autoComplete={field.autocomplete}
            onChange={e => onChange(field.name, e.target.value)}
            value={getValue(field.name)}
            disabled={loading}
          />
        </Row>
      ))}
      <Row>
        <Submit value={title} disabled={loading} />
      </Row>
      <LoadingWrapper open={loading}>
        <Loading />
      </LoadingWrapper>
    </Form>
  );
};
