import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";

export default ({ data: { loading, lesson, error } }) => {
  const NoResult = () => <div>No Result</div>;
  const LessonDetails = () => (
    <div>
      <div>{lesson.name}</div>
      <div>{lesson.description}</div>
    </div>
  );
  return (
    <Page
      title={useTranslate("lesson")}
      description={useTranslate("lessonDescription")}
      loading={loading}
      error={error}
    >
      {!lesson && <NoResult />}
      {lesson && <LessonDetails />}
    </Page>
  );
};
