import React, { useState, useContext } from "react";
import styled from "styled-components";
import Modal, { ModalProvider, BaseModalBackground } from "styled-react-modal";
import { LargeVideoContext } from "./";
import Grid from "styled-components-grid";

export const MeetingWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.grayLight};
`;

export const MeetingContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const LargeVideoWrapper = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  overflow: hidden;
  opacity: ${({ hidden }) => (hidden ? `0` : `1`)};
`;

const LargeVideoItem = styled.video`
  transform: ${({ reverse }) => (reverse === 1 ? `scaleX(-1)` : null)};
  object-fit: ${({ contain }) => (contain === 1 ? `contain` : `cover`)};
  padding: ${({ contain }) => (contain ? `10px` : null)};
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-position: center center;
  box-sizing: border-box;
`;

const LargeVideoBlurItem = styled(LargeVideoItem)`
  filter: blur(15px);
  object-fit: cover;
  padding: 0;
`;

export const LargeVideo = ({ reverse, contain, largeId, blurId, hidden }) => (
  <LargeVideoWrapper hidden={hidden}>
    <LargeVideoBlurItem autoPlay reverse={reverse} id={blurId} />
    <LargeVideoItem autoPlay reverse={reverse} contain={contain} id={largeId} />
  </LargeVideoWrapper>
);

export const MediaWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: ${({ hidden }) => (hidden ? `0` : `1`)};
`;

const MediaListWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const MediaGridWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  opacity: ${({ hidden }) => (hidden ? `0` : `1`)};
`;

const MediaGridSection = styled(Grid)`
  height: calc(100% - 10px);
  padding: 5px;
  justify-content: center;
`;

const ParticipantGridItemWrapper = styled(Grid.Unit)`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100%;
`;

const ParticipantGridItemContainer = styled.div`
  max-height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 4;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.base};
  border: ${({ theme }) => `2px solid ${theme.color.blue}`};
  overflow: hidden;
  background-image: url(${require("../../static/icons/avatar.svg")});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  &:after {
    padding-bottom: ${(9 / 16) * 100}%;
    content: "";
    display: block;
  }
`;

const MediaListSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  flex: ${({ remote }) => (remote === 1 ? `1` : null)};
  justify-content: ${({ remote }) => (remote === 1 ? `flex-end` : null)};
`;

const ParticipantWrapper = styled.div`
  width: 160px;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  z-index: 4;
  border-radius: ${({ theme }) => theme.borderRadius.base};
  border: ${({ theme }) => `2px solid ${theme.color.blue}`};
  margin: 5px;
  overflow: hidden;
  background-image: url(${require("../../static/icons/avatar.svg")});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.color.dark};
`;

const ParticipantAudio = styled.audio``;

const ParticipantVideo = styled.video`
  width: 100%;
  height: 100%;
  transform: ${({ reverse }) => (reverse === 1 ? `scaleX(-1)` : null)};
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
`;

const ParticipantGridVideo = styled.video`
  transform: ${({ reverse }) => (reverse === 1 ? `scaleX(-1)` : null)};
  object-fit: ${({ contain }) => (contain === 1 ? `contain` : `cover`)};
  padding: ${({ contain }) => (contain ? `10px` : null)};
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-position: center center;
  box-sizing: border-box;
`;

const ParticipantGridVideoBlur = styled(ParticipantGridVideo)`
  filter: blur(15px);
  object-fit: cover;
  padding: 0;
`;

const ParticipantMedia = ({ type, bind }) => {
  if (type === "audio") {
    return <ParticipantAudio {...bind} />;
  } else if (type === "video") {
    return <ParticipantVideo {...bind} />;
  } else {
    return null;
  }
};

const ParticipantGridMedia = ({ contain, type, bind }) => {
  const addLarge = { id: `${bind.id}Large`, contain: contain };
  const addLargeBlur = { id: `${bind.id}LargeBlur`, contain: contain };
  if (type === "video") {
    return [
      <ParticipantGridVideoBlur key={0} {...bind} {...addLargeBlur} />,
      <ParticipantGridVideo key={1} {...bind} {...addLarge} />
    ];
  } else {
    return null;
  }
};

const ParticipantOverlay = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.color.opacity};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 0.7em;
  text-align: center;
`;

const ParticipantGridName = styled.div`
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.color.opacity};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  font-weight: 400;
  font-size: 0.7em;
`;

const ParticipantIconList = styled.div`
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ParticipantIcon = styled.div`
  height: 15px;
  width: 15px;
  background-image: ${({ src }) => (src ? `url(${src})` : null)};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 5px;
  z-index: 1;
  display: inline-block;
`;

const isOneTrackMuted = tracks => {
  let muted = false;
  tracks.forEach(({ track }) => (track.isMuted() ? (muted = true) : null));
  return muted;
};

const Participant = ({ participant, mediaNodes, muted }) => {
  const { attachToLargeVideo } = useContext(LargeVideoContext) || {
    attachToLargeVideo: () => {}
  };
  return (
    <ParticipantWrapper onClick={() => attachToLargeVideo(mediaNodes)}>
      <ParticipantIconList>
        {muted && (
          <ParticipantIcon
            src={require("../../static/icons/microphone.muted.svg")}
          />
        )}
      </ParticipantIconList>
      <ParticipantOverlay>{participant.name}</ParticipantOverlay>
      {mediaNodes.map(({ type, bind, track }, index) => (
        <ParticipantMedia type={type} bind={bind} track={track} key={index} />
      ))}
    </ParticipantWrapper>
  );
};

const generateGridSize = amount => {
  return 1 / Math.ceil(Math.sqrt(amount));
};

const ParticipantGridItem = ({ participant, mediaNodes, muted, amount }) => {
  const { attachToLargeVideo, toggleGridView } = useContext(
    LargeVideoContext
  ) || {
    attachToLargeVideo: () => {},
    toggleGridView: () => {}
  };
  return (
    <ParticipantGridItemWrapper size={generateGridSize(amount)}>
      <ParticipantGridItemContainer
        onClick={() => {
          attachToLargeVideo(mediaNodes);
          toggleGridView();
        }}
      >
        <ParticipantIconList>
          {muted && (
            <ParticipantIcon
              src={require("../../static/icons/microphone.muted.svg")}
            />
          )}
        </ParticipantIconList>
        <ParticipantGridName>{participant.name}</ParticipantGridName>
        {mediaNodes.map(({ type, bind, track, contain }, index) => (
          <ParticipantGridMedia
            type={type}
            contain={contain}
            bind={bind}
            track={track}
            key={index}
          />
        ))}
      </ParticipantGridItemContainer>
    </ParticipantGridItemWrapper>
  );
};

const renderMediaListItems = (mediaNodes, remote, muted = []) => {
  const group = mediaNodes.reduce((array, current) => {
    if (!array[current.participant.id]) array[current.participant.id] = [];
    array[current.participant.id].push(current);
    return array;
  }, {});
  const participants = Object.entries(group).map(([key, value]) => ({
    participant: { ...value[0].participant, source: value[0].source },
    mediaNodes: value,
    muted: isOneTrackMuted(value) || muted.includes(value[0].participant.id)
  }));
  return (
    <MediaListSection remote={remote}>
      {participants.map((participant, index) => (
        <Participant {...participant} key={index} />
      ))}
    </MediaListSection>
  );
};

const renderMediaGridItems = (mediaNodes, muted = []) => {
  const group = mediaNodes.reduce((array, current) => {
    if (!array[current.participant.id]) array[current.participant.id] = [];
    array[current.participant.id].push(current);
    return array;
  }, {});
  const participants = Object.entries(group).map(([key, value]) => ({
    participant: { ...value[0].participant, source: value[0].source },
    mediaNodes: value,
    muted: isOneTrackMuted(value) || muted.includes(value[0].participant.id)
  }));
  return (
    <MediaGridSection>
      {participants.map((participant, index) => (
        <ParticipantGridItem
          {...participant}
          amount={participants.length}
          key={index}
        />
      ))}
    </MediaGridSection>
  );
};

export const MediaList = ({ local, remote, muted }) => (
  <MediaListWrapper>
    {renderMediaListItems(local, 0, muted)}
    {renderMediaListItems(remote, 1, muted)}
  </MediaListWrapper>
);

export const MediaGrid = ({ hidden, nodes, muted }) => (
  <MediaGridWrapper hidden={hidden}>
    {renderMediaGridItems(nodes, muted)}
  </MediaGridWrapper>
);

const ToolbarWrapper = styled.div`
  height: 4em;
  width: 100%;
  background: ${({ theme }) => theme.color.dark};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ToolbarSection = styled.div`
  flex: 1;
  margin: 0 0.5em;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align};
  color: ${({ theme }) => theme.color.white};
`;

const ToolbarModal = Modal.styled`
	width: 400px;
  height: 250px;
	display: flex;
	flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: ${props => props.opacity};
  transition: opacity ease 500ms;
`;

const ToolbarModalContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${props => props.opacity};
  transition: opacity ease 200ms;
`;

const ToolbarItemWrapper = styled.div`
  height: 1.75em;
  width: 1.75em;
  margin: 0 0.25em;
  border-radius: ${({ theme }) => theme.borderRadius.base};
  cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
  background: ${({ theme, active }) => (active ? theme.color.opacity : null)};
  opacity: ${({ disabled }) => (disabled ? `0.5` : null)};
  &:hover {
    background: ${({ theme, disabled }) =>
      disabled ? null : theme.color.opacity};
  }
`;

const ToolbarItemImage = styled.div`
  width: calc(100% - 0.4em);
  height: calc(100% - 0.4em);
  margin: 0.2em;
  background-image: ${({ icon }) => (icon ? `url(${icon})` : null)};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const Toolbar = ({ left, center, right }) => (
  <ModalProvider backgroundComponent={FadingBackground}>
    <ToolbarWrapper>
      <ToolbarSection align={"flex-start"}>{left}</ToolbarSection>
      <ToolbarSection align={"center"}>{center}</ToolbarSection>
      <ToolbarSection align={"flex-end"}>{right}</ToolbarSection>
    </ToolbarWrapper>
  </ModalProvider>
);

export const ToolbarItem = ({
  icon,
  getDisabled,
  getActive,
  hide,
  onClick,
  modalContent
}) => {
  const [isActive, setActive] = useState(false);
  const toggleActive = () => setActive(!isActive);
  const [opacity, setOpacity] = useState(0);
  const beforeClose = () =>
    new Promise(resolve => {
      setOpacity(0);
      setTimeout(resolve, 200);
    });
  const afterOpen = () => {
    setTimeout(() => {
      setOpacity(1);
    });
  };
  if (hide) {
    return null;
  }
  return (
    <ToolbarItemWrapper
      disabled={getDisabled}
      active={getActive !== undefined ? getActive : isActive}
      onClick={
        isActive
          ? () => {}
          : onClick && typeof onClick === "function"
          ? onClick
          : toggleActive
      }
    >
      <ToolbarItemImage icon={icon} />
      {modalContent !== undefined && (
        <ToolbarModal
          isOpen={getActive !== undefined ? getActive : isActive}
          afterOpen={afterOpen}
          beforeClose={beforeClose}
          onBackgroundClick={toggleActive}
          onEscapeKeydown={toggleActive}
          opacity={opacity}
          backgroundProps={{ opacity }}
        >
          <Toolbar
            right={[
              <ToolbarItem
                key={0}
                icon={require("../../static/icons/close.svg")}
                onClick={toggleActive}
              />
            ]}
          />
          <ToolbarModalContent>{modalContent}</ToolbarModalContent>
        </ToolbarModal>
      )}
    </ToolbarItemWrapper>
  );
};
