import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";

export default ({ data: { loading, event, error } }) => {
  const NoResult = () => <div>No Result</div>;
  const EventDetails = () => (
    <div>
      <div>{event.name}</div>
      <div>{event.description}</div>
    </div>
  );
  return (
    <Page
      title={useTranslate("event")}
      description={useTranslate("eventDescription")}
      loading={loading}
      error={error}
    >
      {!event && <NoResult />}
      {event && <EventDetails />}
    </Page>
  );
};
