import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import { Link } from "react-router-dom";
import { useRoute } from "../hooks/use-route";

export default ({ data: { loading, meetings, error } }) => {
  const Meetings = () => (
    <div>
      {meetings.map(({ id, name }, index) => (
        <div key={index}>
          <Link to={`${useRoute("meeting")}/${id}`}>{name}</Link>
        </div>
      ))}
    </div>
  );
  return (
    <Page
      title={useTranslate("meeting")}
      description={useTranslate("meetingDescription")}
      loading={loading}
      error={error}
    >
      {meetings && <Meetings />}
    </Page>
  );
};
