import LangRouter from "../containers/langRouter";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
  query language($lng: String) {
    language: Language(key: $lng) {
      id
      fallback
      flag
      dir
      key
      name
      translations {
        id
        key {
          id
          key
        }
        value
      }
    }
    loggedInUser {
      id
      firstname
      lastname
      role
      organisations
      defaultOrganisation
      language
      profileImage
    }
  }
`;
const QueryParams = {
  options: props => ({
    variables: { lng: props.match.params.lng }
  })
};
export default graphql(Query, QueryParams)(LangRouter);
