import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";

export default () => {
  return (
    <Page
      title={useTranslate("survey")}
      description={useTranslate("surveyDescription")}
    >
      <div>Survey</div>
    </Page>
  );
};
