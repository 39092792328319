import React, { createContext } from "react";
import { Switch } from "react-router-dom";
import Page from "../components/page";
import Error from "../components/error";
import Loading from "../components/loading";
import {
  PublicRoute,
  ProtectedRoute,
  RedirectStart,
  SimpleRoute
} from "../components/router";
import Login from "../screens/login";
import Register from "../screens/register";
import Dashboard from "../screens/dashboard";
import Profile from "../screens/profile";
import Meeting from "../screens/meeting";
import Course from "../screens/course";
import Lesson from "../screens/lesson";
import Event from "../screens/event";
import CourseDetail from "../screens/courseDetail";
import EventDetail from "../screens/eventDetail";
import LessonDetail from "../screens/lessonDetail";
import MeetingDetail from "../screens/meetingDetail";
import PublicMeeting from "../screens/publicMeeting";
import Imprint from "../screens/imprint";
import Policy from "../screens/policy";
import System from "../screens/system";
import Survey from "../screens/survey";
import NavigationPage from "../components/navigation-page";

import { setLang } from "../helpers/language";
import user from "../helpers/user";
import routes from "../constants/routes";

export const LanguageContext = createContext(null);
export const UserContext = createContext(null);
export const RouteContext = createContext(null);
export const AuthContext = createContext(null);

export default ({
  data: { loading, language, loggedInUser, error },
  history,
  location,
  match,
  languages
}) => {
  const logout = () => {
    user.setUser({});
    history.replace(`/${language.key}`);
  };
  const login = usr => {
    user.setUser(usr);
    history.replace(`/${language.key}`);
  };
  const restructTranslations = translations => {
    let res = {};
    translations.forEach(t => {
      res[t.key.key] = t.value;
    });
    return res;
  };
  const translate = key => {
    return (
      restructTranslations(language.translations)[key] ||
      `{{${key}_${language.key}}}`
    );
  };
  const updateLang = lang => {
    history.replace(location.pathname.replace(match.url, `/${lang}`));
  };
  if (loading) {
    return (
      <Page title="Loading">
        <Loading />
      </Page>
    );
  }
  if (error) {
    return (
      <Page title="Error">
        <Error error="Error" />
      </Page>
    );
  }
  if (language) {
    setLang(language.key);
    if (loggedInUser && loggedInUser.id) {
      user.extendUser(loggedInUser);
      /*
      if (loggedInUser.language && loggedInUser.language.key !== language.key) {
        updateLang(loggedInUser.language.key);
      }
      */
    }
  }
  return (
    <LanguageContext.Provider
      value={{
        language: {
          ...language,
          translations: restructTranslations(language.translations)
        },
        languages,
        translate,
        updateLang
      }}
    >
      <UserContext.Provider value={{ ...user.getUser() }}>
        <RouteContext.Provider value={routes}>
          <AuthContext.Provider value={{ logout, login }}>
            <NavigationPage>
              <Switch>
                <PublicRoute
                  exact
                  path={`/${language.key}${routes.login}`}
                  component={Login}
                />
                <PublicRoute
                  exact
                  path={`/${language.key}${routes.register}`}
                  component={Register}
                />
                <ProtectedRoute
                  exact
                  roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                  path={`/${language.key}${routes.dashboard}`}
                  component={Dashboard}
                />
                <ProtectedRoute
                  exact
                  roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                  path={`/${language.key}${routes.course}`}
                  component={Course}
                />
                <ProtectedRoute
                  exact
                  roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                  path={`/${language.key}${routes.course}/:id`}
                  component={CourseDetail}
                />
                <ProtectedRoute
                  exact
                  roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                  path={`/${language.key}${routes.lesson}`}
                  component={Lesson}
                />
                <ProtectedRoute
                  exact
                  roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                  path={`/${language.key}${routes.lesson}/:id`}
                  component={LessonDetail}
                />
                <ProtectedRoute
                  exact
                  roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                  path={`/${language.key}${routes.survey}`}
                  component={Survey}
                />
                <ProtectedRoute
                  exact
                  roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                  path={`/${language.key}${routes.event}`}
                  component={Event}
                />
                <ProtectedRoute
                  exact
                  roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                  path={`/${language.key}${routes.event}/:id`}
                  component={EventDetail}
                />
                <ProtectedRoute
                  exact
                  roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                  path={`/${language.key}${routes.meeting}`}
                  component={Meeting}
                />
                <ProtectedRoute
                  exact
                  roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                  path={`/${language.key}${routes.meeting}/:id`}
                  component={MeetingDetail}
                />
                <SimpleRoute
                  path={`/${language.key}${routes.sharedMeeting}/:id`}
                  component={PublicMeeting}
                />
                <ProtectedRoute
                  exact
                  roles={["ORG_ADMIN", "ORG_USER", "ADMIN"]}
                  path={`/${language.key}${routes.profile}`}
                  component={Profile}
                />
                <SimpleRoute
                  exact
                  path={`/${language.key}${routes.imprint}`}
                  component={Imprint}
                />
                <SimpleRoute
                  exact
                  path={`/${language.key}${routes.policy}`}
                  component={Policy}
                />
                <SimpleRoute
                  exact
                  path={`/${language.key}${routes.system}`}
                  component={System}
                />
                <RedirectStart />
              </Switch>
            </NavigationPage>
          </AuthContext.Provider>
        </RouteContext.Provider>
      </UserContext.Provider>
    </LanguageContext.Provider>
  );
};
