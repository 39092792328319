import React, { useEffect, useState, createContext } from "react";
import { useUser } from "../../hooks/use-user";
import meetingFunctions from "./meetingFunctions";
import {
  MeetingWrapper,
  MeetingContent,
  Toolbar,
  ToolbarItem,
  LargeVideo,
  MediaWrapper,
  MediaList,
  MediaGrid
} from "./components";

export const LargeVideoContext = createContext();

export default ({ roomname, update = () => {} }) => {
  const { firstname, lastname, token } = useUser();
  const displayName =
    firstname && lastname
      ? `${firstname} ${lastname}`
      : firstname
      ? firstname
      : lastname
      ? lastname
      : "Gast";

  //const [customDisplayname, setCustomDisplayName] = useState(null);
  const [gridView, setGridView] = useState(true);
  const toggleGridView = () => setGridView(!gridView);
  const [mediaNodes, setMediaNodes] = useState([]);
  const [meetingState, setMeetingState] = useState(null);
  const [isScreenSharing, setScreenSharing] = useState(false);
  const [isAudioMuted, setAudioMuted] = useState(false);
  const [isMuted, setMuted] = useState([]);
  const [roomState, setRoomState] = useState(null);
  const [isLeaved, setIsLeaved] = useState(false);
  const [largeVideoParticipant, setLargeVideoParticipant] = useState(null);
  const [isLargeVideoReverse, setIsLargeVideoReverse] = useState(0);
  const [isLargeVideoContain, setIsLargeVideoContain] = useState(0);
  const [isTrackError, setTrackError] = useState(null);

  const attachToLargeVideo = mediaNodes => {
    const mediaNode = mediaNodes.filter(n => n.type === "video");
    if (mediaNode.length === 1) {
      const node = mediaNode[0];
      const largeVideoElement = document.getElementById("largeVideo");
      if (largeVideoElement) {
        setLargeVideoParticipant(node.participant.id);
        node.track.attach(largeVideoElement);
        setIsLargeVideoReverse(node.bind.reverse);
        setIsLargeVideoContain(node.contain);
        if (node.contain) {
          const largeVideoElementBlur = document.getElementById(
            "largeVideoBlur"
          );
          node.track.attach(largeVideoElementBlur);
        }
      }
    }
  };

  const chooseLargeVideo = value => {
    if (
      value.filter(
        ({ participant }) => participant.id !== largeVideoParticipant
      ).length > 0
    ) {
      const localVideos = value.filter(
        ({ source, type }) => source === "local" && type === "video"
      );
      const remoteVideos = value.filter(
        ({ source, type }) => source === "remote" && type === "video"
      );
      if (localVideos.length === 1) {
        attachToLargeVideo(localVideos);
      } else if (remoteVideos.length >= 1) {
        attachToLargeVideo(remoteVideos);
      }
    }
  };

  const updateMediaNodes = value => {
    setMediaNodes(value);
    value.forEach(({ track, bind: { id } }) => {
      console.log(track, id);
      track.attach(document.getElementById(`${id}`));
      track.attach(document.getElementById(`${id}Large`));
      track.attach(document.getElementById(`${id}LargeBlur`));
    });
    chooseLargeVideo(value);
  };

  useEffect(() => {
    const meeting = meetingFunctions({
      roomName: roomname,
      displayName: displayName,
      getMediaNodes: updateMediaNodes,
      getScreenSharing: setScreenSharing,
      getAudioMuted: setAudioMuted,
      getMuted: setMuted,
      getRoom: setRoomState,
      getTrackError: setTrackError
    });
    meeting.setup();
    setMeetingState(meeting);
    return () => {
      meeting.unload();
    };
  }, []);
  if (isLeaved) {
    return <div>leaved meeting</div>;
  } else {
    return (
      <LargeVideoContext.Provider
        value={{ attachToLargeVideo, toggleGridView }}
      >
        <MeetingWrapper>
          {isTrackError && (
            <MeetingContent>
              <div>{JSON.stringify(isTrackError)}</div>
            </MeetingContent>
          )}
          {!isTrackError && (
            <MeetingContent>
              <LargeVideo
                hidden={gridView}
                reverse={isLargeVideoReverse}
                contain={isLargeVideoContain}
                largeId="largeVideo"
                blurId="largeVideoBlur"
              />
              <MediaGrid
                hidden={!gridView}
                nodes={mediaNodes}
                muted={isMuted}
              />
              <MediaWrapper hidden={gridView}>
                <MediaList
                  local={mediaNodes.filter(({ source }) => source === "local")}
                  remote={mediaNodes.filter(
                    ({ source }) => source === "remote"
                  )}
                  muted={isMuted}
                />
              </MediaWrapper>
            </MeetingContent>
          )}
          <Toolbar
            left={[
              <ToolbarItem
                key={0}
                icon={require("../../static/icons/screen.svg")}
                getDisabled={
                  meetingState && !meetingState.desktopSharingSupported()
                }
                getActive={isScreenSharing}
                onClick={() => {
                  meetingState &&
                    meetingState.desktopSharingSupported() &&
                    meetingState.toggleScreenSharing();
                }}
              />
            ]}
            center={[
              <ToolbarItem
                key={0}
                icon={require("../../static/icons/camera.svg")}
                getDisabled={false}
                getActive={false}
                onClick={() => console.log("pressed camera")}
              />,
              <ToolbarItem
                key={1}
                icon={require("../../static/icons/phone.svg")}
                onClick={() => {
                  roomState &&
                    roomState.leave().then(() => {
                      setIsLeaved(true);
                    });
                }}
              />,
              <ToolbarItem
                key={2}
                icon={
                  isAudioMuted
                    ? require("../../static/icons/microphone.muted.svg")
                    : require("../../static/icons/microphone.svg")
                }
                getActive={isAudioMuted}
                onClick={() => {
                  meetingState && meetingState.toggleAudioMuted();
                }}
              />
            ]}
            right={[
              <ToolbarItem
                key={0}
                getActive={gridView && mediaNodes.length > 0}
                icon={require("../../static/icons/mode.svg")}
                onClick={toggleGridView}
              />,
              <ToolbarItem
                key={1}
                hide={token === undefined ? true : false}
                icon={require("../../static/icons/share.svg")}
                modalContent={
                  <div>
                    <div>Modal Content goes here</div>
                    <div onClick={() => update({ public: true })}>
                      update Meeting to public
                    </div>
                    <div onClick={() => update({ public: false })}>
                      update Meeting to private
                    </div>
                  </div>
                }
              />
            ]}
          />
        </MeetingWrapper>
      </LargeVideoContext.Provider>
    );
  }
};
