import PublicMeeting from "../containers/publicMeeting";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
	query meeting($id: ID) {
		meeting: Meeting(id: $id) {
			id
			createdAt
			updatedAt
			name
			description
			public
			password
			active
			moderator {
				id
				firstname
				lastname
			}
			participants {
				id
				firstname
				lastname
			}
		}
	}
`;
export const publicMeetingSubscription = gql`
	subscription($id: ID) {
		Meeting(filter: { node: { id: $id } }) {
			node {
				id
				createdAt
				updatedAt
				name
				description
				public
				password
				active
				moderator {
					id
					firstname
					lastname
				}
				participants {
					id
					firstname
					lastname
				}
			}
			updatedFields
		}
	}
`;
const QueryParams = {
	options: props => ({
		variables: { id: props.match.params.id },
	}),
};
export default graphql(Query, QueryParams)(PublicMeeting);
