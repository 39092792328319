import React from "react";
import styled from "styled-components";

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const ErrorImage = styled.div`
  background-image: ${({ src }) => (src ? `url(${src})` : null)};
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 400px;
  height: 400px;
`;

const ErrorText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xl3};
`;

export default ({ error }) => (
  <ErrorWrapper>
    <ErrorImage src={require("../../static/undraw/warning.svg")} />
    <ErrorText>{error}</ErrorText>
  </ErrorWrapper>
);
