import { version, name } from "../../package.json";

const storagePrefix = `${name}.${version}`;

const get = (key, isJson = false) => {
  if (isJson) {
    return JSON.parse(localStorage.getItem(`${storagePrefix}@${key}`));
  } else {
    return localStorage.getItem(`${storagePrefix}@${key}`);
  }
};

const set = (key, value, isJson = false) => {
  localStorage.setItem(
    `${storagePrefix}@${key}`,
    isJson ? JSON.stringify(value) : value
  );
};

export default { get, set };
