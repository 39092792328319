import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import Meeting from "../components/meeting";
import { publicMeetingSubscription } from "../screens/publicMeeting";

export default ({
  match: {
    params: { id }
  },
  data: { loading, meeting, error, subscribeToMore }
}) => {
  const PublicMeeting = () => <Meeting roomname={meeting.id} />;
  const NotPublic = () => <div>Not Public</div>;
  const NotActive = () => <div>Not active yet. Please wait.</div>;
  const NotFound = () => <div>Not found</div>;
  const renderComponent = meeting => {
    if (meeting) {
      if (!meeting.public) {
        return <NotPublic />;
      } else {
        if (!meeting.active) {
          return <NotActive />;
        } else {
          return <PublicMeeting />;
        }
      }
    } else {
      return <NotFound />;
    }
  };
  subscribeToMore({
    document: publicMeetingSubscription,
    variables: {
      id
    },
    updateQuery: (prev, { subscriptionData }) => {
      console.log("updateQuery", prev, subscriptionData);
      if (!subscriptionData.data.Meeting) {
        return prev;
      }
      return Object.assign(
        {},
        { ...prev },
        { ...subscriptionData.data.Meeting.node }
      );
    }
  });
  return (
    <Page
      title={
        meeting && meeting.name
          ? `${useTranslate("meeting")}: ${meeting.name}`
          : useTranslate("meeting")
      }
      description={meeting && meeting.description}
      error={error}
      loading={loading}
    >
      {renderComponent(meeting)}
    </Page>
  );
};
