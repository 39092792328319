import Event from "../containers/event";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
	query events {
		events: allEvents {
			id
			createdAt
			updatedAt
			name
			description
		}
	}
`;
export default graphql(Query)(Event);
