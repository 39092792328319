import LessonDetail from "../containers/lessonDetail";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
	query lesson($id: ID) {
		lesson: Lesson(id: $id) {
			id
			createdAt
			updatedAt
			name
			description
		}
	}
`;
const QueryParams = {
	options: props => ({
		variables: { id: props.match.params.id },
	}),
};
export default graphql(Query, QueryParams)(LessonDetail);
