import React, { useState } from "react";
import Page from "../page";
import Loading from "../loading";
import Error from "../error";
import styled from "styled-components";
import { useRoute } from "../../hooks/use-route";
import { useTranslate } from "../../hooks/use-translate";
import OutsideClickHandler from "react-outside-click-handler";
import ReactSwipeEvents from "react-swipe-events";
import { FiMenu, FiGlobe, FiPlus, FiMinus } from "react-icons/fi";
import ReactCountryFlag from "react-country-flag";
import { useUser } from "../../hooks/use-user";
import { useLanguage } from "../../hooks/use-language";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";
import { withRouter } from "react-router-dom";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { imageApi } from "../../helpers/image-api";

const UPDATE_USER_LANG = gql`
  mutation updateUserLang($user: ID!, $language: ID!) {
    updateUser(id: $user, languageId: $language) {
      id
    }
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuBarWrapper = styled.div`
  height: 60px;
  background: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => `0px ${theme.spacing.base}`};
  z-index: 20;
  box-shadow: ${({ theme }) => theme.shadow.baseOutset};
`;

const MenuBarIconWrapper = styled.div`
  height: 1em;
  width: 1em;
  padding: ${({ theme }) => theme.spacing.sm};
  cursor: pointer;
  > svg {
    width: 100%;
    height: 100%;
  }
`;

const MenuBarTitle = styled.div`
  flex: 1;
  padding: ${({ theme }) => `0 ${theme.spacing.lg}`};
`;

const MenuOptionWrapper = styled.div`
  opacity: ${({ open }) => (open ? `1` : `0`)};
  position: absolute;
  right: 0;
  top: ${({ open }) => (open ? `0` : `-100%`)};
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.6s ease-in-out, top 0.6s ease-in-out;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 10;<
`;

const MenuOverlayWrapper = styled.div`
  opacity: ${({ open }) => (open ? `1` : `0`)};
  position: absolute;
  top: 0;
  left: ${({ open, dir }) => (dir === "LTR" ? (open ? `0` : `-100%`) : null)};
  right: ${({ open, dir }) => (dir === "RTL" ? (open ? `0` : `-100%`) : null)};
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.6s ease-in-out, left 0.6s ease-in-out,
    right 0.6s ease-in-out;
  z-index: 30;
`;

const MenuOverlayContent = styled.div`
  height: 100%;
  width: 80%;
  max-width: 350px;
  position: relative;
  left: ${({ open, dir }) => (dir === "LTR" ? (open ? `0` : `-100%`) : null)};
  right: ${({ open, dir }) => (dir === "RTL" ? (open ? `0` : `-100%`) : null)};
  background-color: ${({ theme }) => theme.color.white};
  transition: left 0.6s ease-in-out, right 0.6s ease-in-out;
  & > div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const MenuOptionContent = styled.div`
  height: 100px;
  width: 100%;
  position: relative;
  top: ${({ open }) => (open ? `0` : `-100%`)};
  background-color: ${({ theme }) => theme.color.white};
  transition: top 0.6s ease-in-out;
  padding-top: 60px;
  & > div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    background-color: ${({ theme }) => theme.color.blueLight};
  }
`;

const MenuOptionOutside = styled.div`
  display: flex;
  flex: 1;
  background: transparent;
  color: ${({ theme }) => theme.color.white};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: 400;
`;

const MenuOverlayHeaderWrapper = styled.div`
  height: 140px;
  background-color: ${({ theme }) => theme.color.pinkLight};
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const MenuOverlayHeaderAvatar = styled.div`
  background: ${({ theme }) => theme.color.blueLight};
  height: 170px;
  width: 170px;
  border-radius: 50%;
  margin-left: ${({ dir }) => (dir === "LTR" ? `-45px` : null)};
  margin-right: ${({ dir }) => (dir === "RTL" ? `-45px` : null)};
  margin-top: -15px;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadow.baseOutset};
  &:after {
    content: "";
    position: absolute;
    height: 65%;
    width: 65%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: ${({ theme }) => theme.color.grayLight};
    border-radius: 50%;
    overflow: hidden;
    background-image: ${({ avatar }) => (avatar ? `url(${avatar})` : null)};
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: ${({ theme }) => theme.shadow.baseOutset};
  }
`;

const MenuOverlayHeaderContent = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  box-sizing: border-box;
`;

const MenuOverlayBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const MenuOverlayBodyContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.xl5};
  box-sizing: border-box;
  overflow-y: auto;
`;

const MenuOverlayBodyContentItems = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MenuOverlayBodyContentLogout = styled.div`
  color: ${({ theme }) => theme.color.darkLight};
  font-size: ${({ theme }) => theme.fontSize.sm};
  text-decoration: none;
  margin: ${({ theme, visible }) =>
    visible ? `${theme.spacing.xl3} 0 ${theme.spacing.base} 0` : null};
  cursor: pointer;
  width: 100%;
  transition: all 0.3s ease-in-out;
  max-height: ${({ visible }) => (visible ? `3em` : `0`)};
  opacity: ${({ visible }) => (visible ? `1` : `0`)};
`;

const MenuOverlayTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.lg};
`;

const MenuOverlaySub = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.color.darkLight};
`;

const LanguageItem = styled.div`
  width: 110px;
  min-width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding: 1em;
  box-sizing: border-box;
  background-color: ${({ active }) =>
    active ? `rgba(255, 255, 255, 0.5)` : null};
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const LanguageName = styled.div`
  margin-top: 0.5em;
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

const CountryFlag = ({ code }) => (
  <ReactCountryFlag
    code={code}
    svg
    styleProps={{
      width: "1.5em",
      height: "1em"
    }}
  />
);

const MenuOverlayItemGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MenuOverlayItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing.base} 0`};
  cursor: pointer;
`;

const MenuOverlayItemHeaderTitle = styled.div`
  display: flex;
  flex: 1;
  word-break: break-all;
  text-decoration: ${({ open }) => (open ? `underline` : null)};
  text-underline-position: under;
`;

const MenuOverlayItemHeaderIcon = styled.div`
  width: 1em;
  height: 1em;
  padding: ${({ theme }) => theme.spacing.sm};
  margin-left: ${({ dir, theme }) => (dir === "LTR" ? theme.spacing.sm : null)};
  margin-right: ${({ dir, theme }) =>
    dir === "RTL" ? theme.spacing.sm : null};
  & > div {
    height: 100%;
    width: 100%;
  }
`;

const MenuOverlayItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  max-height: ${({ open }) => (open ? `400px` : `0px`)};
  transition: all 0.5s ease-in-out;
`;

const MenuOverlayItemLink = styled(Link)`
  color: ${({ theme }) => theme.color.darkLight};
  font-size: ${({ theme }) => theme.fontSize.sm};
  text-decoration: ${({ open }) => (open ? `underline` : `none`)};
  margin: ${({ theme }) => `${theme.spacing.base} 0`};
  cursor: pointer;
  text-underline-position: under;
`;

const MenuOverlayItemGroup = withRouter(
  ({ location: { pathname }, title, sub = [], toggleMenu }) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen(!open);
    const { dir } = useLanguage();
    const subPaths = sub.map(s => s.path);
    const isSubActive = subPaths.includes(pathname);
    return (
      <MenuOverlayItemGroupWrapper>
        <MenuOverlayItemHeader onClick={toggleOpen}>
          <MenuOverlayItemHeaderTitle open={isSubActive}>
            {title}
          </MenuOverlayItemHeaderTitle>
          <MenuOverlayItemHeaderIcon dir={dir}>
            {open ? <FiMinus /> : <FiPlus />}
          </MenuOverlayItemHeaderIcon>
        </MenuOverlayItemHeader>
        <MenuOverlayItemContent open={open}>
          {sub.map(({ title, path }, index) => (
            <MenuOverlayItemLink
              key={index}
              to={path}
              open={pathname === path}
              onClick={() => {
                toggleMenu();
              }}
            >
              {title}
            </MenuOverlayItemLink>
          ))}
        </MenuOverlayItemContent>
      </MenuOverlayItemGroupWrapper>
    );
  }
);

const Menu = ({ menu, menuOpen, toggleMenu, optionOpen, toggleOption }) => {
  const { updateLang, dir, key, languages } = useLanguage();
  const { firstname, lastname, role, token, profileImage } = useUser();
  const userId = useUser().id || {};
  const { logout } = useAuth();
  return (
    <MenuWrapper>
      <MenuBarWrapper>
        <MenuBarIconWrapper
          onClick={() => {
            if (optionOpen) {
              toggleOption();
            }
            toggleMenu();
          }}
        >
          <FiMenu />
        </MenuBarIconWrapper>
        <MenuBarTitle>Quizium</MenuBarTitle>
        <MenuBarIconWrapper
          onClick={() => {
            if (menuOpen) {
              toggleMenu();
            }
            toggleOption();
          }}
        >
          <FiGlobe />
        </MenuBarIconWrapper>
      </MenuBarWrapper>
      <MenuOptionWrapper open={optionOpen}>
        <Mutation mutation={UPDATE_USER_LANG}>
          {updateUser => (
            <MenuOptionContent open={optionOpen}>
              <div>
                {languages
                  .filter(l => l.key === key)
                  .map(({ name, key, flag, id }, index) => (
                    <LanguageItem
                      active
                      onClick={() => {
                        if (token) {
                          updateUser({
                            variables: {
                              user: userId,
                              language: id
                            }
                          });
                        }
                        updateLang(key);
                        toggleOption();
                      }}
                      key={index}
                    >
                      <CountryFlag code={flag} />
                      <LanguageName>{name}</LanguageName>
                    </LanguageItem>
                  ))}
                {languages
                  .filter(l => l.key !== key)
                  .map(({ name, key, flag, id }, index) => (
                    <LanguageItem
                      onClick={() => {
                        if (token) {
                          updateUser({
                            variables: {
                              user: userId,
                              language: id
                            }
                          });
                        }
                        updateLang(key);
                        toggleOption();
                      }}
                      key={index}
                    >
                      <CountryFlag code={flag} />
                      <LanguageName>{name}</LanguageName>
                    </LanguageItem>
                  ))}
              </div>
            </MenuOptionContent>
          )}
        </Mutation>

        <SwipeWrapper>
          <ReactSwipeEvents
            onSwipedUp={(e, originalY, endY) => {
              if (optionOpen) {
                toggleOption();
              }
            }}
          >
            <MenuOptionOutside onClick={toggleOption}>
              {useTranslate("chooseLanguage")}
            </MenuOptionOutside>
          </ReactSwipeEvents>
        </SwipeWrapper>
      </MenuOptionWrapper>
      <MenuOverlayWrapper open={menuOpen} dir={dir}>
        <MenuOverlayContent open={menuOpen} dir={dir}>
          <OutsideClickHandler
            onOutsideClick={() => {
              if (menuOpen) {
                toggleMenu();
              }
            }}
          >
            <MenuOverlayHeaderWrapper>
              <MenuOverlayHeaderAvatar
                dir={dir}
                avatar={
                  token && profileImage
                    ? imageApi(profileImage.secret, 400, 400)
                    : require("../../static/images/avatar.png")
                }
              />
              <MenuOverlayHeaderContent>
                <MenuOverlayTitle>
                  {firstname} {lastname}
                </MenuOverlayTitle>
                {role && (
                  <MenuOverlaySub>
                    {useTranslate(role.toLowerCase())}
                  </MenuOverlaySub>
                )}
              </MenuOverlayHeaderContent>
            </MenuOverlayHeaderWrapper>
            <MenuOverlayBodyWrapper>
              <MenuOverlayBodyContent>
                <MenuOverlayBodyContentItems>
                  {menu.map(({ title, sub }, index) => (
                    <MenuOverlayItemGroup
                      key={index}
                      title={title}
                      sub={sub}
                      toggleMenu={toggleMenu}
                    />
                  ))}
                </MenuOverlayBodyContentItems>
                <MenuOverlayBodyContentLogout
                  visible={token ? true : false}
                  onClick={() => {
                    toggleMenu();
                    logout();
                  }}
                >
                  {useTranslate("logout")}
                </MenuOverlayBodyContentLogout>
              </MenuOverlayBodyContent>
            </MenuOverlayBodyWrapper>
          </OutsideClickHandler>
        </MenuOverlayContent>
      </MenuOverlayWrapper>
    </MenuWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Content = ({ loading, error, children }) => {
  if (loading) {
    return <Loading />;
  } else if (error) {
    return <Error error={error} />;
  } else if (!loading && !error && children) {
    return <ContentWrapper>{children}</ContentWrapper>;
  }
};

const SwipeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  width: 100%;
  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    width: 100%;
  }
`;

export default ({ title, description, children, error, loading }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const [optionOpen, setOptionOpen] = useState(false);
  const toggleOption = () => setOptionOpen(!optionOpen);
  const { dir } = useLanguage();
  const { token } = useUser();
  const publicMenu = [
    {
      title: useTranslate("user"),
      sub: [
        {
          title: useTranslate("login"),
          path: useRoute("login")
        },
        {
          title: useTranslate("register"),
          path: useRoute("register")
        }
      ]
    },
    {
      title: useTranslate("about"),
      sub: [
        {
          title: useTranslate("imprint"),
          path: useRoute("imprint")
        },
        {
          title: useTranslate("policy"),
          path: useRoute("policy")
        },
        {
          title: useTranslate("systemInfo"),
          path: useRoute("system")
        }
      ]
    }
  ];
  const privateMenu = [
    {
      title: useTranslate("content"),
      sub: [
        {
          title: useTranslate("dashboard"),
          path: useRoute("dashboard")
        },
        {
          title: useTranslate("course"),
          path: useRoute("course")
        },
        {
          title: useTranslate("lesson"),
          path: useRoute("lesson")
        },
        {
          title: useTranslate("survey"),
          path: useRoute("survey")
        }
      ]
    },
    {
      title: useTranslate("communication"),
      sub: [
        {
          title: useTranslate("meeting"),
          path: useRoute("meeting")
        },
        {
          title: useTranslate("event"),
          path: useRoute("event")
        }
      ]
    },
    {
      title: useTranslate("about"),
      sub: [
        {
          title: useTranslate("imprint"),
          path: useRoute("imprint")
        },
        {
          title: useTranslate("policy"),
          path: useRoute("policy")
        },
        {
          title: useTranslate("systemInfo"),
          path: useRoute("system")
        }
      ]
    }
  ];
  return (
    <SwipeWrapper>
      <ReactSwipeEvents
        onSwipedRight={() => {
          if (dir === "RTL") {
            if (menuOpen) {
              setMenuOpen(false);
            }
          }
        }}
        onSwipedLeft={() => {
          if (dir === "LTR") {
            if (menuOpen) {
              setMenuOpen(false);
            }
          }
        }}
      >
        <Page title={title} description={description}>
          <Menu
            menu={token ? privateMenu : publicMenu}
            menuOpen={menuOpen}
            toggleMenu={toggleMenu}
            optionOpen={optionOpen}
            toggleOption={toggleOption}
          />
          <Content loading={loading} error={error}>
            {children}
          </Content>
        </Page>
      </ReactSwipeEvents>
    </SwipeWrapper>
  );
};
