import * as Sentry from "@sentry/browser";
import React, { useEffect, useState } from "react";
import { ApolloProvider } from "react-apollo";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import client from "../client/graphql";
import { SENTRY } from "../constants/server";
import Loading from "../components/loading";
import theme from "../constants/theme";
import { getMetaTag } from "../helpers/meta-tag";
import Navigation from "../screens/navigation";
import Helmet from "react-helmet";
import RubikBoldWoff from "../static/fonts/rubik-bold.woff";
import RubikLightWoff from "../static/fonts/rubik-light.woff";
import RubikMediumWoff from "../static/fonts/rubik-medium.woff";
import RubikRegularWoff from "../static/fonts/rubik-regular.woff";

const GlobalStyle = createGlobalStyle`
	@font-face {
			font-family: 'Rubik';
			src: url(${RubikBoldWoff}) format('woff');
			font-weight: bold;
			font-style: normal;
	}
	@font-face {
			font-family: 'Rubik';
			src: url(${RubikMediumWoff}) format('woff');
			font-weight: 500;
			font-style: normal;
	}
	@font-face {
			font-family: 'Rubik';
			src: url(${RubikLightWoff}) format('woff2');
			font-weight: 300;
			font-style: normal;
	}
	@font-face {
			font-family: 'Rubik';
			src: url(${RubikRegularWoff}) format('woff2');
			font-weight: normal;
			font-style: normal;
	}
	body, html {
		margin: 0;
		padding: 0;
		font-family: 'Rubik';
		font-size: ${theme.fontSize.default};
		color: ${theme.color.dark};
		font-weight: 500;
		position: fixed;
    overflow: hidden;
	}
	* {
		font-family: 'Rubik';
		font-weight: 500;
	}
	html, body, #root {
		height: 100%;
		width: 100%;
		overflow-x: hidden;
		background: ${theme.color.white};
	}
	#root {
		overflow-y: auto;
  	-webkit-overflow-scrolling: touch;
	}
	textarea,
	input[type="text"],
	input[type="button"],
	input[type="password"],
	input[type="email"],
	input[type="submit"] {
			-webkit-appearance: none;
			border-radius: 0;
	}
`;

const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
`;

export default () => {
  const [cdn, setCdn] = useState(null);

  const getCdn = () => {
    fetch("/manifest.json").then(response => {
      setCdn(response.headers.get("x-now-trace") || "%CDN%");
    });
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      Sentry.init({
        dsn: SENTRY,
        release: getMetaTag("commit", "%COMMIT%"),
        environment: getMetaTag("branch", "%BRANCH%")
        // ignoreErrors: ["ResizeObserver loop limit exceeded"],
      });
    }
    getCdn();
  }, []);

  return (
    <ApolloProvider client={client}>
      <GlobalStyle />
      <Helmet>
        <meta name="cdn" content={cdn} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <NavigationWrapper>
          {!cdn && <Loading />}
          {cdn && <Navigation />}
        </NavigationWrapper>
      </ThemeProvider>
    </ApolloProvider>
  );
};
