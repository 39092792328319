import storage from "../helpers/storage";
import detectBrowserLanguage from "detect-browser-language";

const browserLanguage = () => {
  let language = detectBrowserLanguage();
  if (language.includes("-")) {
    return language.substr(0, language.indexOf("-")).toLowerCase();
  } else {
    return language.toLowerCase();
  }
};

export const getLang = languages => {
  const storageLang = storage.get("language");
  const storageUser = storage.get("user");
  if (
    storageUser &&
    storageUser.language &&
    languages.some(({ key }) => key === storageUser.language.key)
  ) {
    return storageUser.language.key;
  } else if (storageLang && languages.some(({ key }) => key === storageLang)) {
    return storageLang;
  } else {
    if (languages.some(({ key }) => key === browserLanguage())) {
      return browserLanguage();
    } else if (
      languages.filter(({ fallback }) => fallback === true).length === 1
    ) {
      return languages.filter(({ fallback }) => fallback === true)[0].key;
    } else {
      return languages[0].key;
    }
  }
};

export const setLang = language => {
  storage.set("language", language);
};

export const getRoute = languages => {
  return languages
    .map(({ key }, index) => {
      if (index < languages.length - 1) {
        return `${key}|`;
      } else {
        return key;
      }
    })
    .join("");
};

export default { getLang, getRoute, setLang };
