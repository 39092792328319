import Register from "../containers/register";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const authenticateUser = gql`
  mutation signupUser(
    $email: String!
    $password: String!
    $firstname: String
    $lastname: String
  ) {
    signupUser(
      email: $email
      password: $password
      firstname: $firstname
      lastname: $lastname
    ) {
      id
      token
      firstname
      lastname
      role
      organisations
      defaultOrganisation
      language
      profileImage
    }
  }
`;
export default graphql(authenticateUser, { name: "signupUser" })(Register);
