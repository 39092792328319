import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import { getMetaTag } from "../helpers/meta-tag";
import Content from "../components/content";

export default () => {
  const branch = getMetaTag("branch", "%BRANCH%");
  const commit = getMetaTag("commit", "%COMMIT%");
  const cdn = getMetaTag("cdn", "%CDN%");
  const version = `${commit}.${branch}`;
  return (
    <Page
      title={useTranslate("systemInfo")}
      description={useTranslate("systemInfo")}
    >
      <Content>
        <h1>{useTranslate("systemInfo")}</h1>
        <p>
          {useTranslate("version")}: {version}
          <br />
          {useTranslate("serverNode")}: {cdn}
        </p>
      </Content>
    </Page>
  );
};
