import React from "react";
import styled from "styled-components";
import { DoubleBounce } from "styled-spinkit";
import theme from "../../constants/theme";

const LoadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
`;

const StyledDoubleBounce = () => (
	<DoubleBounce color={theme.color.blue} size={60} />
);

export default () => (
	<LoadingWrapper>
		<StyledDoubleBounce />
	</LoadingWrapper>
);
