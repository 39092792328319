import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { useRoute } from "../../hooks/use-route";
import { useUser } from "../../hooks/use-user";

export const PublicRoute = ({ component: Component, path, exact }) => {
  const { token } = useUser();
  if (token) {
    return (
      <Redirect
        to={{
          pathname: useRoute("dashboard"),
          state: { from: path }
        }}
      />
    );
  } else {
    return <Route path={path} exact={exact} component={Component} />;
  }
};

export const ProtectedRoute = ({
  roles = [],
  component: Component,
  path,
  exact
}) => {
  const { token, role } = useUser();
  const isAllowed = token && role && roles.includes(role);
  if (token && isAllowed) {
    return <Route path={path} exact={exact} component={Component} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: useRoute("login"),
          state: { from: path }
        }}
      />
    );
  }
};

export const SharedRoute = ({
  component: Component,
  path,
  exact,
  privatePath
}) => {
  const { token } = useUser();
  if (token) {
    return (
      <Route
        exact={exact}
        path={path}
        render={props => (
          <Redirect
            to={privatePath.replace(`/:id`, `/${props.match.params.id}`)}
          />
        )}
      />
    );
  } else {
    return <Route path={path} exact={exact} component={Component} />;
  }
};

export const RedirectStart = withRouter(({ location: { pathname }, path }) => {
  if (pathname) {
    return (
      <Redirect
        to={{
          pathname: useRoute("dashboard"),
          state: { from: path }
        }}
      />
    );
  } else {
    return null;
  }
});

export const SimpleRoute = props => <Route {...props} />;
