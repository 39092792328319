import jquery from "jquery";

const fallbackOptions = {
	hosts: {
		domain: "meet.jit.si",
		muc: "conference.meet.jit.si",
	},
	bosh: "https://meet.jit.si/http-bind",
	disableSimulcast: true,
	enableRemb: true,
	enableTcc: true,
	enableP2P: true, // flag to control P2P connections
	// New P2P options
	p2p: {
		enabled: true,
		preferH264: true,
		disableH264: true,
		useStunTurn: true, // use XEP-0215 to fetch STUN and TURN server for the P2P connection
		stunServers: [
			{ urls: "stun:stun.l.google.com:19302" },
			{ urls: "stun:stun1.l.google.com:19302" },
			{ urls: "stun:stun2.l.google.com:19302" },
		],
	},
	useStunTurn: true,
};

export default ({
	roomName = "",
	displayName = "",
	options = fallbackOptions,
	getRoom = () => {},
	getConnection = () => {},
	getTracks = () => {},
	getMediaNodes = () => {},
	getIsJoined = () => {},
	getScreenSharing = () => {},
	getAudioMuted = () => {},
	getMuted = () => {},
	getTrackError = () => {},
}) => {
	// import from global space and set to global space
	window.$ = jquery;
	const { JitsiMeetJS } = window;

	// vars
	let isJoined = false;
	let screenSharing = false;
	let audioMuted = false;
	let tracks = [];
	let mediaNodes = [];
	let connection = null;
	let room = null;
	let muted = [];

	const addMuted = participant => {
		muted = [...muted.filter(m => m !== participant), participant];
		getMuted(muted);
	};

	const removeMuted = participant => {
		muted = muted.filter(m => m !== participant);
		getMuted(muted);
	};

	const setIsJoined = value => {
		isJoined = value;
		getIsJoined(isJoined);
	};

	const setScreenSharing = value => {
		screenSharing = value;
		getScreenSharing(screenSharing);
	};

	const setAudioMuted = value => {
		audioMuted = value;
		getAudioMuted(audioMuted);
	};

	const setTracks = value => {
		tracks = value;
		getTracks(tracks);
	};

	const setMediaNodes = value => {
		mediaNodes = value;
		getMediaNodes(mediaNodes);
	};

	const setConnection = value => {
		connection = value;
		getConnection(connection);
	};

	const setRoom = value => {
		room = value;
		getRoom(room);
	};

	// config
	const confOptions = {
		openBridgeChannel: true,
	};

	const initOptions = {
		disableAudioLevels: true,
		desktopSharingChromeExtId: "mbocklcggfhnbahlnepmldehdhpjfcjp",
		desktopSharingChromeDisabled: false,
		desktopSharingChromeSources: ["screen", "window", "tab"],
		desktopSharingChromeMinExtVersion: "0.1",
		desktopSharingFirefoxDisabled: false,
	};

	// functions
	const setup = () => {
		JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
		JitsiMeetJS.init(initOptions);
		setConnection(new JitsiMeetJS.JitsiConnection(null, null, options));
		connection.addEventListener(
			JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
			onConnectionSuccess
		);
		connection.addEventListener(
			JitsiMeetJS.events.connection.CONNECTION_FAILED,
			onConnectionFailed
		);
		connection.addEventListener(
			JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
			onDisconnect
		);
		JitsiMeetJS.mediaDevices.addEventListener(
			JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
			onDeviceListChanged
		);
		connection.connect();
		JitsiMeetJS.createLocalTracks({
			devices: ["audio", "video"],
			resolution: 1080,
			constraints: {
				video: {
					aspectRatio: 16 / 9,
					height: {
						ideal: 720,
						max: 1080,
						min: 720,
					},
				},
			},
			minFps: 10,
			maxFps: 30,
		})
			.then(onLocalTracks)
			.catch(error => getTrackError(error));
		window.addEventListener("unload", () => {
			unload();
		});
		window.addEventListener("beforeunload", () => {
			unload();
		});
	};

	const unload = () => {
		tracks
			.filter(({ source }) => source === "local")
			.forEach(({ track }) => {
				!track.disposed && track.dispose();
			});
		room && room.leave();
		connection && connection.disconnect();
	};

	const onConnectionSuccess = () => {
		setRoom(connection.initJitsiConference(roomName, confOptions));
		room.setDisplayName(displayName);
		room.on(JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrack);
		room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, onTrackRemoved);
		room.on(
			JitsiMeetJS.events.conference.CONFERENCE_JOINED,
			onConferenceJoined
		);
		room.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
		/*
    room.on(JitsiMeetJS.events.conference.USER_JOINED, id => { console.log("user join:", id) });
    room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, track => {console.log(`${track.getType()} - ${track.isMuted()}`)});
    room.on(JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED, (userID, displayName) => {console.log(`${userID} - ${displayName}`)});
    room.on(JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,(userID, audioLevel) => console.log(userID, audioLevel));
    */
		room.join();
	};

	const onConnectionFailed = () => {
		console.error("Connection Failed!");
	};

	const onDisconnect = () => {
		console.log("disconnect!");
		connection.removeEventListener(
			JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
			onConnectionSuccess
		);
		connection.removeEventListener(
			JitsiMeetJS.events.connection.CONNECTION_FAILED,
			onConnectionFailed
		);
		connection.removeEventListener(
			JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
			onDisconnect
		);
	};

	const onDeviceListChanged = devices => {
		console.info("current devices", devices);
	};

	const onLocalTracks = localTracks => {
		setTracks([
			...tracks.filter(t => t.source !== "local"),
			...localTracks.map(t => ({ track: t, source: "local" })),
		]);
		tracks
			.filter(({ source }) => source === "local")
			.forEach(({ track }) => {
				track.addEventListener(
					JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
					audioLevel => console.log(`Audio Level local: ${audioLevel}`)
				);
				track.addEventListener(
					JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
					track =>
						track.isMuted()
							? addMuted(track.getParticipantId())
							: removeMuted(track.getParticipantId())
				);
				track.addEventListener(
					JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
					() => {
						if (screenSharing) {
							toggleScreenSharing();
						}
					}
				);
				track.addEventListener(
					JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
					deviceId =>
						console.log(`track audio output device was changed to ${deviceId}`)
				);
				setMediaNodes([
					...mediaNodes,
					{
						participant: {
							id: "local",
							name: `${displayName} (me)`,
						},
						type: track.getType(),
						source: "local",
						contain:
							track.getType() === "video" && track.videoType === "desktop"
								? 1
								: 0,
						track: track,
						bind: {
							autoPlay: 1,
							muted: true,
							id: `local${track.getType()}`,
							reverse:
								track.getType() === "video" && track.videoType === "desktop"
									? 0
									: 1,
						},
					},
				]);
				if (isJoined) {
					room.addTrack(track);
				}
			});
	};

	const onRemoteTrack = remoteTrack => {
		if (remoteTrack.isLocal()) {
			return;
		}
		if (
			tracks.filter(
				({ source, track }) =>
					source === "remote" &&
					track.getParticipantId() === remoteTrack.getParticipantId()
			).length === 0
		) {
			setTracks([...tracks, { track: remoteTrack, source: "remote" }]);
		}
		remoteTrack.addEventListener(
			JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
			audioLevel => console.log(`Audio Level remote: ${audioLevel}`)
		);
		remoteTrack.addEventListener(
			JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
			track =>
				track.isMuted()
					? addMuted(track.getParticipantId())
					: removeMuted(track.getParticipantId())
		);
		remoteTrack.addEventListener(
			JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
			() => console.log("remote track stoped")
		);
		remoteTrack.addEventListener(
			JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
			deviceId =>
				console.log(`track audio output device was changed to ${deviceId}`)
		);
		setMediaNodes([
			...mediaNodes,
			{
				participant: {
					id: remoteTrack.getParticipantId(),
					name:
						remoteTrack.conference.participants[remoteTrack.getParticipantId()]
							._displayName,
				},
				type: remoteTrack.getType(),
				source: "remote",
				contain:
					remoteTrack.getType() === "video" &&
					remoteTrack.videoType === "desktop"
						? 1
						: 0,
				track: remoteTrack,
				bind: {
					autoPlay: 1,
					id: `remote${remoteTrack.getParticipantId()}${remoteTrack.getType()}`,
					reverse: 0,
				},
			},
		]);
	};

	const onConferenceJoined = () => {
		setIsJoined(true);
		tracks
			.filter(({ source }) => source === "local")
			.forEach(({ track }) => {
				room.addTrack(track);
			});
	};

	const onUserLeft = id => {
		if (
			tracks.filter(
				({ source, track }) =>
					source === "remote" && track.getParticipantId() === id
			).length === 0
		) {
			return;
		}
		tracks
			.filter(({ source }) => source === "remote")
			.forEach(({ track }) => {
				track.detach(
					document.getElementById(
						`remote${track.getParticipantId()}${track.getType()}`
					)
				);
				track.detach(
					document.getElementById(
						`remote${track.getParticipantId()}${track.getType()}Large`
					)
				);
				track.detach(document.getElementById(`largeVideo`));
				track.detach(document.getElementById(`largeVideoBlur`));
			});
	};

	const onTrackRemoved = track => {
		if (!track.isLocal()) {
			setTracks(tracks.filter(t => t !== tracks));
			setMediaNodes(
				mediaNodes.filter(n => n.participant.id !== track.getParticipantId())
			);
		}
	};

	const removeOldVideoTrack = (t, cb = () => {}) => {
		tracks
			.filter(
				({ source, track }) => source === "local" && track.getType() === "video"
			)
			.forEach(({ track }) => {
				//track.dispose().then(() => {
				room.removeTrack(track).then(() => {
					track.dispose();
					track.detach(document.getElementById("localvideo"));
					track.detach(document.getElementById("largeVideo"));
					track.detach(document.getElementById("largeVideoBlur"));
					setTracks([
						...tracks.filter(
							({ source, track }) =>
								source !== "local" && track.getType() !== "video"
						),
					]);
					setMediaNodes([
						...mediaNodes.filter(
							({ source, track }) =>
								source !== "local" && track.getType() !== "video"
						),
					]);
					cb(t);
				});
				//});
			});
	};

	const toggleScreenSharing = () => {
		if (!screenSharing) {
			JitsiMeetJS.createLocalTracks({
				devices: ["audio", "desktop"],
				resolution: 1080,
				constraints: {
					video: {
						aspectRatio: 16 / 9,
						height: {
							ideal: 720,
							max: 1080,
							min: 720,
						},
					},
				},
				minFps: 10,
				maxFps: 30,
			})
				.then(t => {
					setScreenSharing(true);
					removeOldVideoTrack(t, onLocalTracks);
				})
				.catch(error => {
					if (error.name === "gum.chrome_extension_user_canceled") {
						setScreenSharing(true);
						toggleScreenSharing();
					}
				});
		} else {
			JitsiMeetJS.createLocalTracks({
				devices: ["audio", "video"],
				resolution: 1080,
				constraints: {
					video: {
						aspectRatio: 16 / 9,
						height: {
							ideal: 720,
							max: 1080,
							min: 720,
						},
					},
				},
				minFps: 10,
				maxFps: 30,
			})
				.then(t => {
					setScreenSharing(false);
					removeOldVideoTrack(t, onLocalTracks);
				})
				.catch(error => {
					console.log(error);
				});
		}
	};

	const toggleAudioMuted = () => {
		const localAudios = mediaNodes.filter(
			({ source, type }) => source === "local" && type === "audio"
		);
		if (localAudios.length === 1) {
			const localAudio = localAudios[0].track;
			if (localAudio.isMuted()) {
				localAudio.unmute().then(() => setAudioMuted(false));
			} else {
				localAudio.mute().then(() => setAudioMuted(true));
			}
		}
	};

	const desktopSharingSupported = () => {
		return JitsiMeetJS.isDesktopSharingEnabled();
	};

	return {
		setup,
		unload,
		toggleScreenSharing,
		toggleAudioMuted,
		desktopSharingSupported,
	};
};
