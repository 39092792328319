import { useContext } from "react";
import { LanguageContext } from "../containers/langRouter";

export const useLanguage = () => {
	const {
		languages,
		updateLang,
		language: { key, dir, id },
	} = useContext(LanguageContext) || {
		updateLang: () => {},
		language: { key: "", dir: "", id: "" },
	};
	return { key, dir, id, updateLang, languages };
};
