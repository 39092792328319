import React, { createContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import LangRouter from "../screens/langRouter";
import { getLang, getRoute } from "../helpers/language";
import Loading from "../components/loading";
import Page from "../components/page";
import Error from "../components/error";

export const LanguagesContext = createContext(null);

export default ({ data: { loading, error, languages } }) => {
  if (loading) {
    return (
      <Page title="Loading">
        <Loading />
      </Page>
    );
  } else if (error) {
    return (
      <Page title="Error">
        <Error error={error} />
      </Page>
    );
  } else if (!loading && !error && languages) {
    const currentLang = getLang(languages);
    return (
      <LanguagesContext.Provider value={{ languages }}>
        <Router>
          <Switch>
            <Route
              path={`/:lng(${getRoute(languages)})`}
              component={props => (
                <LangRouter {...props} languages={languages} />
              )}
            />
            {currentLang && <Redirect from={`/`} to={`/${currentLang}`} />}
          </Switch>
        </Router>
      </LanguagesContext.Provider>
    );
  }
};
