import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import { Link } from "react-router-dom";
import { useRoute } from "../hooks/use-route";

export default ({ data: { loading, lessons, error } }) => {
  const Lessons = () => (
    <div>
      {lessons.map(({ id, name }, index) => (
        <Link key={index} to={`${useRoute("lesson")}/${id}`}>
          {name}
        </Link>
      ))}
    </div>
  );
  return (
    <Page
      title={useTranslate("lesson")}
      description={useTranslate("lessonDescription")}
      loading={loading}
      error={error}
    >
      {lessons && <Lessons />}
    </Page>
  );
};
