import Login from "../containers/login";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const authenticateUser = gql`
  mutation authenticateUser($email: String!, $password: String!) {
    authenticateUser(email: $email, password: $password) {
      id
      token
      firstname
      lastname
      role
      organisations
      defaultOrganisation
      language
      profileImage
    }
  }
`;
export default graphql(authenticateUser, { name: "authenticateUser" })(Login);
